/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Cookies from "js-cookie";
import { Nav, NavItem } from 'reactstrap';

/**
 * Internal Dependencies
 */
import '../../../../common-assets/js/yaybar/yaybar';
import { initPluginYaybar } from '../../../../common-assets/js/rootui-parts/initPluginYaybar';
import Dropdown from '../bs-dropdown';
import Icon from '../icon';

import { updateAuth as actionUpdateAuth } from '../../actions';

window.RootUI.initPluginYaybar = initPluginYaybar;
var mydevice =   window.innerWidth;

/**
 * Component
 */
class PageYaybar extends Component {
    constructor( props ) {
        super( props );

        this.renderSubmenus = this.renderSubmenus.bind( this );
        this.logOut = this.logOut.bind( this );
    }

    componentDidMount() {
        window.RootUI.initPluginYaybar();
    }

    logOut() {
        const {
            updateAuth,
        } = this.props;
        updateAuth( {
            token2: '',
        } );
    }

    renderSubmenus( nav, returnObject = false ) {
        let thereIsActive = false;

        const result = Object.keys( nav ).map( ( url ) => {
            const data = nav[ url ];
            const isActive = window.location.hash === `#${ url }`;
            let isOpened = false;

            if ( isActive ) {
                thereIsActive = true;
            }

            let sub = '';
            if ( data.sub ) {
                const subData = this.renderSubmenus( data.sub, true );

                sub = (
                    <ul className="yay-submenu dropdown-triangle">
                        { subData.content }
                    </ul>
                );

                if ( subData.thereIsActive ) {
                    isOpened = true;
                    thereIsActive = true;
                }
            }

            return (
                <React.Fragment key={ `${ url }-${ data.name }` }>
                    { data.label ? (
                        <li className="yay-label">{ data.label }</li>
                    ) : '' }
                    <li className={ classnames( {
                        'yay-item-active': isActive,
                        'yay-submenu-open': isOpened,
                    } ) }>
                        { data.name ? (
                            <NavLink
                                to={ data.sub ? '#' : url }
                                className={ data.sub ? 'yay-sub-toggle' : '' }
                            >
                                { data.icon ? (
                                    <>
                                        <span className="yay-icon">
                                            <Icon name={ data.icon } />
                                        </span>
                                        <span>{ data.name }</span>
                                        <span className="rui-yaybar-circle" />
                                    </>
                                ) : (
                                    data.name
                                ) }
                                { data.sub ? (
                                    <span className="yay-icon-collapse">
                                        <Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
                                    </span>
                                ) : '' }
                            </NavLink>
                        ) : '' }
                        { sub }
                    </li>
                </React.Fragment>
            );
        } );

        if ( returnObject ) {
            return {
                content: result,
                thereIsActive,
            };
        }

        return result;
    }

    render() {
    //   var mydevice =  document.getElementsByClassName("sstt");
        var emp_data = Cookies.get('token2');
        console.log("emp_data", emp_data);
        var emp_data2 = JSON.parse(emp_data);
        console.log("emp_data2__________________",emp_data2);
        //   const emp_id = emp_data2["data"][0]["id"];
        if (emp_data2 == "" || emp_data2 == undefined) {
            console.log("No cookies");
        } else {
            if(emp_data2["data"][0]["type"] == undefined){
                var type2 = '';
                console.log("emp_type$$$$$$$$$$$$$$$$$$$$$$$$$$$$$", type2);
            }else{
                var type2 = emp_data2["data"][0]["type"];
                console.log("emp_type$$$$$$$$$$$$$$$$$$$$$$$$$$$$$", type2);
                console.log("emp_type_____", emp_data2["data"][0]["type"]);
            }
           
            var Name = emp_data2["data"][0]["name"];
            console.log("name", Name);
        }
        const isActive = window.location.hash;
        console.log("is active", isActive);
        const {
            settings,
        } = this.props;

        return (
            <>
                <div style={{display:mydevice <= "768"?"block":"none"}}  className={
                    classnames(
                        'yaybar rui-yaybar  yay-gestures  d-none',
                        settings.sidebar_dark && ! settings.night_mode ? 'rui-yaybar-dark' : '',
                        settings.sidebar_static ? 'yay-static' : '',
                        settings.sidebar_effect ? `yay-${ settings.sidebar_effect }` : '',
                    )
                }
                >
  
                                <div style={{ display: type2 == "employee" || type2 == "Employee" ? "block" : "none", width: '100%' }}>
                                <Nav className="flex-column">
                                    <NavItem className="log">
                                        <h2>{Name}</h2>
                                        <Link className={classnames(isActive == "#/calendar" ? 'isactive' : 'isnotactive')} to="/calendar">
                                            <Icon style={{ marginRight: '10px' }} name="home" />
                                            <span >Home</span>
                                        </Link>
                                    </NavItem>
                            

                                    <NavItem className="log">
                                        <Link className={classnames(isActive == "#/typography" ? 'isactive' : 'isnotactive')} to="/typography" >
                                            <Icon style={{ marginRight: '10px' }} name="file" />
                                            <span>Performance</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="log">
                                        <Link className={classnames(isActive == "#/forms-validation" ? 'isactive' : 'isnotactive')} to="/forms-validation" >
                                            <Icon style={{ marginRight: '10px' }} name="file" />
                                            <span>Payslip</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="log">
                                        <Link className={classnames(isActive == "#/profile" ? 'isactive' : 'isnotactive')} to="/profile" >
                                            <Icon style={{ marginRight: '10px' }} name="book" />
                                            <span>My Details</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="log">
                                   
                                        <Link className={classnames(isActive == "#/sign-in" ? 'isactive' : 'isnotactive'), "lof"} onClick={this.logOut}  >
                                            <Icon style={{ marginRight: '10px' }} name="log-out" />
                                            <span>Log Out</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="dropss">
                                        <Dropdown
                                            tag="div"
                                            className="btn-group"
                                            openOnHover
                                            showTriangle
                                        >
                                            <Dropdown.Toggle tag="a"  className="dropdown-item" >
                                                <Icon   name="more-vertical " />
                                                <span >More</span>
                                                <span  className="rui-dropdown-circle dropdown-item" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu tag="ul" className="nav" modifiers={ { offset: { offset: '0,12' } } }>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleNightMode"
                                                            checked={settings.night_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    night_mode: !settings.night_mode,
                                                                });
                                                                console.log("oooooooooooo", !settings.night_mode);
                                                                Cookies.set('night_mode_cookie', !settings.night_mode);
                                                                console.log("Cookies.gt( 'night_mode_cookie')", Cookies.get('night_mode'));

                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleNightMode">
                                                            <Icon name="moon" />
                                                            <span>Night Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSpotlightMode"
                                                            checked={settings.spotlight_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    spotlight_mode: !settings.spotlight_mode,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleSpotlightMode">
                                                            <Icon name="square" />
                                                            <span>Spotlight Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSectionLines"
                                                            checked={settings.show_section_lines}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    show_section_lines: !settings.show_section_lines,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleSectionLines">
                                                            <Icon name="layout" />
                                                            <span>Show section lines</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Sidebar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkSidebar"
                                                            checked={settings.sidebar_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_dark: !settings.sidebar_dark,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleDarkSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleStaticSidebar"
                                                            checked={settings.sidebar_static}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_static: !settings.sidebar_static,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleStaticSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Static</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Navbar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkNavbar"
                                                            checked={settings.nav_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    nav_dark: !settings.nav_dark,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleDarkNavbar">
                                                            <Icon name="menu" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>
                            <div style={{ display: type2 == "admin" ? "block" : "none", width: '100%' }}>
                                <Nav className="flex-column">
                            
                                    <NavItem className="log">
                                        <Link className={classnames(isActive == "#/grid" ? 'isactive' : 'isnotactive')} to="/grid">
                                            <Icon style={{ marginRight: '10px' }} name="book" />
                                            <span>Attendance</span>
                                        </Link>
                                    </NavItem>

                                    <NavItem className="log" >
                                        <Link className={classnames(isActive == "#/mailbox" ? 'isactive' : 'isnotactive')} to="/mailbox">
                                            <Icon style={{ marginRight: '10px' }} name="calendar" />
                                            <span>Performance</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="log">
                                        <Link className={classnames(isActive == "#/colors" ? 'isactive' : 'isnotactive')} to="/colors">
                                            <Icon style={{ marginRight: '10px' }} name="book" />
                                            <span>Master</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="log">
                                        <Link className={ classnames( isActive== "#/file-manager" ? 'isactive' : 'isnotactive' )}to="/file-manager" >
                                            <Icon style={{marginRight:'10px'}} name="user" />
                                            <span>Suspended Employee</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="log">
                                   
                                        <Link className={classnames(isActive == "#/sign-in" ? 'isactive' : 'isnotactive'), "lof"} onClick={this.logOut}  >
                                            <Icon style={{ marginRight: '10px' }} name="log-out" />
                                            <span>Log Out</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="dropss">
                                        <Dropdown
                                            tag="div"
                                            className="btn-group"
                                            openOnHover
                                            showTriangle
                                        >
                                            <Dropdown.Toggle tag="a"  className="dropdown-item">
                                                <Icon name="more-vertical" />
                                                <span>More</span>
                                                <span className="rui-dropdown-circle" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu tag="ul" className="nav" modifiers={ { offset: { offset: '0,12' } } }>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleNightMode"
                                                            checked={settings.night_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    night_mode: !settings.night_mode,
                                                                });
                                                                console.log("oooooooooooo", !settings.night_mode);
                                                                Cookies.set('night_mode_cookie', !settings.night_mode);
                                                                console.log("Cookies.gt( 'night_mode_cookie')", Cookies.get('night_mode'));

                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleNightMode">
                                                            <Icon name="moon" />
                                                            <span>Night Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSpotlightMode"
                                                            checked={settings.spotlight_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    spotlight_mode: !settings.spotlight_mode,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleSpotlightMode">
                                                            <Icon name="square" />
                                                            <span>Spotlight Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSectionLines"
                                                            checked={settings.show_section_lines}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    show_section_lines: !settings.show_section_lines,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleSectionLines">
                                                            <Icon name="layout" />
                                                            <span>Show section lines</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Sidebar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkSidebar"
                                                            checked={settings.sidebar_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_dark: !settings.sidebar_dark,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleDarkSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleStaticSidebar"
                                                            checked={settings.sidebar_static}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_static: !settings.sidebar_static,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleStaticSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Static</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Navbar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkNavbar"
                                                            checked={settings.nav_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    nav_dark: !settings.nav_dark,
                                                                });
                                                            }}
                                                        />
                                                  
                                                        <label className="dropdown-item custom-control-label" htmlFor="toggleDarkNavbar">
                                                            <Icon name="menu" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>
                                    {/* <NavLink
                                        to="#"
                                        onClick={ this.logOut }
                                    >
                                        <span className="yay-icon">
                                            <Icon name="log-out" />
                                        </span>
                                        <span>Log Out</span>
                                        <span className="rui-yaybar-circle"></span>
                                    </NavLink> */}
                </div>
                <div className="rui-yaybar-bg" />
            </>
        );
    }
}

export default connect( ( { settings } ) => (
    {
        settings,
    }
), { updateAuth: actionUpdateAuth } )( PageYaybar );
